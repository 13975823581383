const blockTestimonials = async($block) => {
	const { Swiper } = await import( 'swiper' );
	const { Navigation } = await import( 'swiper/modules' );
	await import( 'swiper/css/bundle' );

	const $sliderWrapPrimary = $block.find('.slider-testimonials-primary-wrap');
	const $sliderWrapSecondary = $block.find('.slider-testimonials-secondary-wrap');
	if ( !$sliderWrapPrimary.length && !$sliderWrapSecondary.length ) { return false; }
	const $sliderPrimary = $sliderWrapPrimary.find('.slider-testimonials-primary');
	const $sliderSecondary = $sliderWrapSecondary.find('.slider-testimonials-secondary');
	const $buttonNextSecondary = $sliderWrapSecondary.find('.slider-nav__next');
	const $buttonPrevSecondary = $sliderWrapSecondary.find('.slider-nav__prev');

	const testimonialsSwiperPrimary = new Swiper($sliderPrimary[0], {
		init: false,
		slidesPerView: 1,
		speed: 500
	});

	const testimonialsSwiperSecondary = new Swiper($sliderSecondary[0], {
		modules: [Navigation],
		init: false,
		slidesPerView: 1,
		speed: 500,
		navigation: {
			nextEl: $buttonNextSecondary[0],
			prevEl: $buttonPrevSecondary[0]
		},
		breakpoints: {
			1024: {
				slidesPerView: 2
			}
		}
	});

	testimonialsSwiperPrimary.on('slideChange', function() {
		testimonialsSwiperSecondary.slideTo(this.realIndex);
	});

	testimonialsSwiperSecondary.on('slideChange', function() {
		testimonialsSwiperPrimary.slideTo(this.realIndex);
	});

	testimonialsSwiperPrimary.init();
	testimonialsSwiperSecondary.init();
};

themeUtils.loadBlock( blockTestimonials, 'video-testimonials', '.section-testimonials' );
